<template>
  <div class="mobile-container">
    <img :src="imagePath" alt="Zhao Leipeng" class="mobile-image" />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      imagePath: require('@/assets/zhaoleipeng.png')
    };
  }
}
</script>

<style>
.mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-image {
  max-width: 100%; /* 图片宽度不超过容器宽度 */
  height: auto; /* 保持原始宽高比 */
}
@media (min-width: 600px) { /* 在较大屏幕上 */
  .mobile-image {
    width: 50%; /* 调整图片大小以适应更大的屏幕 */
  }
}
</style>
